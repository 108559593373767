.margin-null {
  margin: 0;
}

.margin-null-top {
  margin-top: 0;
}

.margin-null-right {
  margin-right: 0;
}

.margin-null-bottom {
  margin-bottom: 0;
}

.margin-null-left {
  margin-left: 0;
}

.margin-xxs {
  margin: 5px;
}

.margin-xxs-top {
  margin-top: 5px;
}

.margin-xxs-right {
  margin-right: 5px;
}

.margin-xxs-bottom {
  margin-bottom: 5px;
}

.margin-xxs-left {
  margin-left: 5px;
}

.margin-xs {
  margin: 10px;
}

.margin-xs-top {
  margin-top: 10px;
}

.margin-xs-right {
  margin-right: 10px;
}

.margin-xs-bottom {
  margin-bottom: 10px;
}

.margin-xs-left {
  margin-left: 10px;
}

.margin-sm {
  margin: 15px;
}

.margin-sm-top {
  margin-top: 15px;
}

.margin-sm-right {
  margin-right: 15px;
}

.margin-sm-bottom {
  margin-bottom: 15px;
}

.margin-sm-left {
  margin-left: 15px;
}

.margin-md {
  margin: 30px;
}

.margin-md-top {
  margin-top: 30px;
}

.margin-md-right {
  margin-right: 30px;
}

.margin-md-bottom {
  margin-bottom: 30px;
}

.margin-md-left {
  margin-left: 30px;
}

.margin-lg {
  margin: 45px;
}

.margin-lg-top {
  margin-top: 45px;
}

.margin-lg-right {
  margin-right: 45px;
}

.margin-lg-bottom {
  margin-bottom: 45px;
}

.margin-lg-left {
  margin-left: 45px;
}

.margin-xlg {
  margin: 60px;
}

.margin-xlg-top {
  margin-top: 60px;
}

.margin-xlg-right {
  margin-right: 60px;
}

.margin-xlg-bottom {
  margin-bottom: 60px;
}

.margin-xlg-left {
  margin-left: 60px;
}

.padding-null {
  padding: 0;
}

.padding-null-top {
  padding-top: 0;
}

.padding-null-right {
  padding-right: 0;
}

.padding-null-bottom {
  padding-bottom: 0;
}

.padding-null-left {
  padding-left: 0;
}

.padding-xxs {
  padding: 5px;
}

.padding-xxs-top {
  padding-top: 5px;
}

.padding-xxs-right {
  padding-right: 5px;
}

.padding-xxs-bottom {
  padding-bottom: 5px;
}

.padding-xxs-left {
  padding-left: 5px;
}

.padding-xs {
  padding: 10px;
}

.padding-xs-top {
  padding-top: 10px;
}

.padding-xs-right {
  padding-right: 10px;
}

.padding-xs-bottom {
  padding-bottom: 10px;
}

.padding-xs-left {
  padding-left: 10px;
}

.padding-sm {
  padding: 15px;
}

.padding-sm-top {
  padding-top: 15px;
}

.padding-sm-right {
  padding-right: 15px;
}

.padding-sm-bottom {
  padding-bottom: 15px;
}

.padding-sm-left {
  padding-left: 15px;
}

.padding-md {
  padding: 30px;
}

.padding-md-top {
  padding-top: 30px;
}

.padding-md-right {
  padding-right: 30px;
}

.padding-md-bottom {
  padding-bottom: 30px;
}

.padding-md-left {
  padding-left: 30px;
}

.padding-lg {
  padding: 45px;
}

.padding-lg-top {
  padding-top: 45px;
}

.padding-lg-right {
  padding-right: 45px;
}

.padding-lg-bottom {
  padding-bottom: 45px;
}

.padding-lg-left {
  padding-left: 45px;
}

.padding-xlg {
  padding: 60px;
}

.padding-xlg-top {
  padding-top: 60px;
}

.padding-xlg-right {
  padding-right: 60px;
}

.padding-xlg-bottom {
  padding-bottom: 60px;
}

.padding-xlg-left {
  padding-left: 60px;
}

.loading-content-xxs {
  border: 1px solid #ddd;
  background: #e5e5e5;
  background: linear-gradient(to right, #eee 8%, #e5e5e5 18%, #eee 33%);

  &:after {
    content: '';
    display: block;
    padding-bottom: 25%;
  }
}

.loading-content-xs {
  border: 1px solid #ddd;
  background: #e5e5e5;
  background: linear-gradient(to right, #eee 8%, #e5e5e5 18%, #eee 33%);

  &:after {
    content: '';
    display: block;
    padding-bottom: 50%;
  }
}

.loading-content-sm {
  border: 1px solid #ddd;
  background: #e5e5e5;
  background: linear-gradient(to right, #eee 8%, #e5e5e5 18%, #eee 33%);

  &:after {
    content: '';
    display: block;
    padding-bottom: 75%;
  }
}

.loading-content-md {
  border: 1px solid #ddd;
  background: #e5e5e5;
  background: linear-gradient(to right, #eee 8%, #e5e5e5 18%, #eee 33%);

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}

.loading-content-lg {
  border: 1px solid #ddd;
  background: #e5e5e5;
  background: linear-gradient(to right, #eee 8%, #e5e5e5 18%, #eee 33%);

  &:after {
    content: '';
    display: block;
    padding-bottom: 130%;
  }
}

.loading-text-xxs {
  height: 12px;
  width: 20%;
}

.loading-text-xs {
  height: 12px;
  width: 40%;
}

.loading-text-sm {
  height: 12px;
  width: 60%;
}

.loading-text-md {
  height: 12px;
  width: 80%;
}

.loading-text-lg {
  height: 12px;
  width: 100%;
}

.loading-text-color-gray-base {
  background: #000000;
  background: linear-gradient(to right, #595959 8%, #000000 18%, #595959 33%);
}

.loading-text-color-gray-darker {
  background: #222222;
  background: linear-gradient(to right, #707070 8%, #222222 18%, #707070 33%);
}

.loading-text-color-gray-dark {
  background: #333333;
  background: linear-gradient(to right, #7a7a7a 8%, #333333 18%, #7a7a7a 33%);
}

.loading-text-color-gray {
  background: #555555;
  background: linear-gradient(to right, #919191 8%, #555555 18%, #919191 33%);
}

.loading-text-color-gray-light {
  background: #777777;
  background: linear-gradient(to right, #a7a7a7 8%, #777777 18%, #a7a7a7 33%);
}

.loading-text-color-gray-lighter {
  background: #eeeeee;
  background: linear-gradient(to right, #f4f4f4 8%, #eeeeee 18%, #f4f4f4 33%);
}

.loading-text-color-brand-primary {
  background: #337ab7;
  background: linear-gradient(to right, #7ba9d0 8%, #337ab7 18%, #7ba9d0 33%);
}

.loading-text-color-brand-success {
  background: #88ccab;
  background: linear-gradient(to right, #b2dec8 8%, #88ccab 18%, #b2dec8 33%);
}

.loading-text-color-brand-info {
  background: #5bc0de;
  background: linear-gradient(to right, #94d6ea 8%, #5bc0de 18%, #94d6ea 33%);
}

.loading-text-color-brand-warning {
  background: #f0ad4e;
  background: linear-gradient(to right, #f5ca8c 8%, #f0ad4e 18%, #f5ca8c 33%);
}

.loading-text-color-brand-danger {
  background: #d30000;
  background: linear-gradient(to right, #e25959 8%, #d30000 18%, #e25959 33%);
}

.loading-content-color-gray-base {
  border: 1px solid #191919;
  background: #000000;
  background: linear-gradient(to right, #595959 8%, #000000 18%, #595959 33%);
}

.loading-content-color-gray-darker {
  border: 1px solid #383838;
  background: #222222;
  background: linear-gradient(to right, #707070 8%, #222222 18%, #707070 33%);
}

.loading-content-color-gray-dark {
  border: 1px solid #474747;
  background: #333333;
  background: linear-gradient(to right, #7a7a7a 8%, #333333 18%, #7a7a7a 33%);
}

.loading-content-color-gray {
  border: 1px solid #666666;
  background: #555555;
  background: linear-gradient(to right, #919191 8%, #555555 18%, #919191 33%);
}

.loading-content-color-gray-light {
  border: 1px solid #858585;
  background: #777777;
  background: linear-gradient(to right, #a7a7a7 8%, #777777 18%, #a7a7a7 33%);
}

.loading-content-color-gray-lighter {
  border: 1px solid #f0f0f0;
  background: #eeeeee;
  background: linear-gradient(to right, #f4f4f4 8%, #eeeeee 18%, #f4f4f4 33%);
}

.loading-content-color-brand-primary {
  border: 1px solid #4888bf;
  background: #337ab7;
  background: linear-gradient(to right, #7ba9d0 8%, #337ab7 18%, #7ba9d0 33%);
}

.loading-content-color-brand-success {
  border: 1px solid #94d1b3;
  background: #88ccab;
  background: linear-gradient(to right, #b2dec8 8%, #88ccab 18%, #b2dec8 33%);
}

.loading-content-color-brand-info {
  border: 1px solid #6bc6e1;
  background: #5bc0de;
  background: linear-gradient(to right, #94d6ea 8%, #5bc0de 18%, #94d6ea 33%);
}

.loading-content-color-brand-warning {
  border: 1px solid #f2b560;
  background: #f0ad4e;
  background: linear-gradient(to right, #f5ca8c 8%, #f0ad4e 18%, #f5ca8c 33%);
}

.loading-content-color-brand-danger {
  border: 1px solid #d71919;
  background: #d30000;
  background: linear-gradient(to right, #e25959 8%, #d30000 18%, #e25959 33%);
}

.ie img[src$='.svg'] {
  width: 100%;
}

.no-border {
  border: none !important;
}

.no-border-radius {
  border-radius: 0 !important;
}

.no-box-shadow {
  box-shadow: none !important;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src*='.svg'] {
    width: 100%;
  }
}

.loading-text-center {
  display: inline-block;
}

.loading-animation-content {
  animation-duration: 800ms;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: spark;
  animation-timing-function: linear;
  background-size: 1000px 104px;
  position: relative;
}

/* Safari 4.0 - 8.0 */
/* Standard syntax */
@keyframes spark {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
