.pl-modal {
  .modal-dialog {
    @media (min-width: 768px) {
      display: inline-block;
      text-align: left;
      vertical-align: middle;
    }
  }

  .modal-label {
    background-color: #414042;
    color: #ffffff;
    margin-bottom: 15px;
    padding: 15px;
    @media (min-width: 768px) {
      display: none;
    }

    .modal-title {
      font-size: 24px;
    }
  }

  .modal-content {
    padding: 15px;
    border-radius: 0;

    .content-list-order-inline {
      font-size: 0;

      .item-section {
        display: inline-block;
        margin-bottom: 30px;
        padding: 0 15px;
        vertical-align: top;
        width: 50%;
        @media (min-width: 768px) {
          width: 33.33333%;
        }
        @media (min-width: 992px) {
          margin-bottom: 0;
          width: 20%;
        }

        .item-number,
        .item-title,
        .item-description {
          font-size: 16px;
        }
      }
    }
  }

  .modal-header {
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;

    .close {
      margin: -2px 0 0 0;
      padding: 0;

      span {
        font-size: 36px;
      }
    }

    .modal-title {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      font-family: 'brandon-grotesque', 'Helvetica Neue';
      letter-spacing: 0;
      text-transform: uppercase;
    }

    .separator-line {
      margin: 15px auto;
      width: 40px;
    }
  }

  .modal-body {
    font-size: 16px;

    p {
      font-size: inherit;
    }

    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
  }

  .modal-footer {
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
  }

  .form-control {
    box-shadow: none;
  }

  .has-error {
    label {
      color: inherit;
    }

    .help-block {
      color: #d30000;
    }

    .form-control {
      border-color: #d30000;

      &:focus {
        border-color: #d30000;
        box-shadow: none;
      }
    }
  }

  &.modal-dark {
    .modal-content {
      background-color: #000000;
      color: #ffffff;
    }

    .modal-header .close {
      color: #eeeeee;
      opacity: 1;

      &:hover,
      &:focus {
        color: #ffffff;
      }
    }

    input {
      &[type='text'],
      &[type='password'],
      &[type='email'],
      &[type='url'] {
        background-color: #000000;
        color: #ffffff;
        width: 100%;
      }
    }

    textarea {
      background-color: #000000;
      color: #ffffff;
      width: 100%;
    }

    input {
      &[type='text']:focus,
      &[type='password']:focus,
      &[type='email']:focus,
      &[type='url']:focus {
        border-color: #ffffff;
      }
    }

    textarea:focus {
      border-color: #ffffff;
    }

    .help-block {
      color: #eeeeee;
    }

    .has-error .help-block {
      color: #d30000;
    }

    .btn-default.inverse {
      border-color: #ffffff;
      color: #ffffff;

      &:hover,
      &:focus {
        background-color: #ffffff;
        border-color: #ffffff;
        color: #000000;
      }
    }

    a {
      color: inherit;
    }
  }

  &.flat .modal-content {
    box-shadow: none;
  }

  &:before {
    @media (min-width: 768px) {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      margin-right: -4px;
    }
  }

  @media (min-width: 768px) {
    text-align: center;
    padding: 0 !important;
  }
}
