.pl-tooltip-disabled .tooltip {
  display: none !important;
}

.tooltip {
  font-size: 14px;
  font-family: 'brandon-grotesque', 'Helvetica Neue';
  font-weight: normal;
}

.tooltip-inner {
  padding: 6px 8px;
  border-radius: 0;
}

.popover {
  &.pl-popover {
    border: none;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
  }

  .image-popover {
    margin-bottom: 15px;
    overflow: hidden;
  }

  .description-paper {
    text-align: center;

    .title-paper {
      font-family: 'Sanchez', 'Helvetica Neue';
      margin-bottom: 5px;
      text-align: center;
      text-transform: uppercase;
      font-weight: 900;
    }

    .more {
      text-align: center;
      margin-top: 10px;

      a {
        color: #ffffff;
        text-decoration: underline;

        &:hover,
        &:focus {
          color: #ffffff;
          text-decoration: underline;
        }
      }
    }
  }
}

.pl-popover {
  .pl-popover__wrapper {
    font-size: 16px;
    font-weight: 500;
    position: relative;
    z-index: 20;

    .popover-inner {
      background: #000000;
      color: #ffffff;
      position: relative;
    }
  }

  .pl-popover__container .container__icon {
    display: none;
  }

  &.top > .arrow:after {
    border-top-color: #000000;
  }

  &.right > .arrow:after {
    border-right-color: #000000;
  }

  &.bottom > .arrow:after {
    border-bottom-color: #000000;
  }

  &.left > .arrow:after {
    border-left-color: #000000;
  }
}

/** Work only for touch event. */

.touch .pl-popover {
  background: transparent;
  cursor: pointer;
  position: fixed;
  margin: auto;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  overflow: auto;
  max-width: none;
  width: 100%;

  &:before {
    background: #000000;
    opacity: 0.5;
    position: fixed;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }

  > .arrow {
    display: none;
  }

  .pl-popover__wrapper {
    display: table;
    margin: auto;
    max-width: 276px;
    height: 100%;
    width: 100%;
  }

  .popover-inner {
    cursor: pointer;
    margin: 15px 0;
  }

  .pl-popover__container {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    width: 100%;

    .container__icon {
      cursor: pointer;
      display: block;
      position: absolute;
      font-size: 24px;
      right: -10px;
      top: -10px;
    }
  }
}

.mobile .image-popover {
  float: left;
  margin-right: 15px;
  width: 50%;
}
