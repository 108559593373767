@media (min-width: 480px) {
  .col-xsm-1,
  .col-xsm-2,
  .col-xsm-3,
  .col-xsm-4,
  .col-xsm-5,
  .col-xsm-6,
  .col-xsm-7,
  .col-xsm-8,
  .col-xsm-9,
  .col-xsm-10,
  .col-xsm-11,
  .col-xsm-12,
  .col-xsm-13,
  .col-xsm-14,
  .col-xsm-15,
  .col-xsm-16,
  .col-xsm-17,
  .col-xsm-18,
  .col-xsm-19,
  .col-xsm-20,
  .col-xsm-21,
  .col-xsm-22,
  .col-xsm-23 {
    float: left;
  }

  .col-xsm-24 {
    float: left;
    width: 100%;
  }

  .col-xsm-23 {
    width: 95.83333333%;
  }

  .col-xsm-22 {
    width: 91.66666667%;
  }

  .col-xsm-21 {
    width: 87.5%;
  }

  .col-xsm-20 {
    width: 83.33333333%;
  }

  .col-xsm-19 {
    width: 79.16666667%;
  }

  .col-xsm-18 {
    width: 75%;
  }

  .col-xsm-17 {
    width: 70.83333333%;
  }

  .col-xsm-16 {
    width: 66.66666667%;
  }

  .col-xsm-15 {
    width: 62.5%;
  }

  .col-xsm-14 {
    width: 58.33333333%;
  }

  .col-xsm-13 {
    width: 54.16666667%;
  }

  .col-xsm-12 {
    width: 50%;
  }

  .col-xsm-11 {
    width: 45.83333333%;
  }

  .col-xsm-10 {
    width: 41.66666667%;
  }

  .col-xsm-9 {
    width: 37.5%;
  }

  .col-xsm-8 {
    width: 33.33333333%;
  }

  .col-xsm-7 {
    width: 29.16666667%;
  }

  .col-xsm-6 {
    width: 25%;
  }

  .col-xsm-5 {
    width: 20.83333333%;
  }

  .col-xsm-4 {
    width: 16.66666667%;
  }

  .col-xsm-3 {
    width: 12.5%;
  }

  .col-xsm-2 {
    width: 8.33333333%;
  }

  .col-xsm-1 {
    width: 4.16666667%;
  }

  .col-xsm-pull-24 {
    right: 100%;
  }

  .col-xsm-pull-23 {
    right: 95.83333333%;
  }

  .col-xsm-pull-22 {
    right: 91.66666667%;
  }

  .col-xsm-pull-21 {
    right: 87.5%;
  }

  .col-xsm-pull-20 {
    right: 83.33333333%;
  }

  .col-xsm-pull-19 {
    right: 79.16666667%;
  }

  .col-xsm-pull-18 {
    right: 75%;
  }

  .col-xsm-pull-17 {
    right: 70.83333333%;
  }

  .col-xsm-pull-16 {
    right: 66.66666667%;
  }

  .col-xsm-pull-15 {
    right: 62.5%;
  }

  .col-xsm-pull-14 {
    right: 58.33333333%;
  }

  .col-xsm-pull-13 {
    right: 54.16666667%;
  }

  .col-xsm-pull-12 {
    right: 50%;
  }

  .col-xsm-pull-11 {
    right: 45.83333333%;
  }

  .col-xsm-pull-10 {
    right: 41.66666667%;
  }

  .col-xsm-pull-9 {
    right: 37.5%;
  }

  .col-xsm-pull-8 {
    right: 33.33333333%;
  }

  .col-xsm-pull-7 {
    right: 29.16666667%;
  }

  .col-xsm-pull-6 {
    right: 25%;
  }

  .col-xsm-pull-5 {
    right: 20.83333333%;
  }

  .col-xsm-pull-4 {
    right: 16.66666667%;
  }

  .col-xsm-pull-3 {
    right: 12.5%;
  }

  .col-xsm-pull-2 {
    right: 8.33333333%;
  }

  .col-xsm-pull-1 {
    right: 4.16666667%;
  }

  .col-xsm-pull-0 {
    right: auto;
  }

  .col-xsm-push-24 {
    left: 100%;
  }

  .col-xsm-push-23 {
    left: 95.83333333%;
  }

  .col-xsm-push-22 {
    left: 91.66666667%;
  }

  .col-xsm-push-21 {
    left: 87.5%;
  }

  .col-xsm-push-20 {
    left: 83.33333333%;
  }

  .col-xsm-push-19 {
    left: 79.16666667%;
  }

  .col-xsm-push-18 {
    left: 75%;
  }

  .col-xsm-push-17 {
    left: 70.83333333%;
  }

  .col-xsm-push-16 {
    left: 66.66666667%;
  }

  .col-xsm-push-15 {
    left: 62.5%;
  }

  .col-xsm-push-14 {
    left: 58.33333333%;
  }

  .col-xsm-push-13 {
    left: 54.16666667%;
  }

  .col-xsm-push-12 {
    left: 50%;
  }

  .col-xsm-push-11 {
    left: 45.83333333%;
  }

  .col-xsm-push-10 {
    left: 41.66666667%;
  }

  .col-xsm-push-9 {
    left: 37.5%;
  }

  .col-xsm-push-8 {
    left: 33.33333333%;
  }

  .col-xsm-push-7 {
    left: 29.16666667%;
  }

  .col-xsm-push-6 {
    left: 25%;
  }

  .col-xsm-push-5 {
    left: 20.83333333%;
  }

  .col-xsm-push-4 {
    left: 16.66666667%;
  }

  .col-xsm-push-3 {
    left: 12.5%;
  }

  .col-xsm-push-2 {
    left: 8.33333333%;
  }

  .col-xsm-push-1 {
    left: 4.16666667%;
  }

  .col-xsm-push-0 {
    left: auto;
  }

  .col-xsm-offset-24 {
    margin-left: 100%;
  }

  .col-xsm-offset-23 {
    margin-left: 95.83333333%;
  }

  .col-xsm-offset-22 {
    margin-left: 91.66666667%;
  }

  .col-xsm-offset-21 {
    margin-left: 87.5%;
  }

  .col-xsm-offset-20 {
    margin-left: 83.33333333%;
  }

  .col-xsm-offset-19 {
    margin-left: 79.16666667%;
  }

  .col-xsm-offset-18 {
    margin-left: 75%;
  }

  .col-xsm-offset-17 {
    margin-left: 70.83333333%;
  }

  .col-xsm-offset-16 {
    margin-left: 66.66666667%;
  }

  .col-xsm-offset-15 {
    margin-left: 62.5%;
  }

  .col-xsm-offset-14 {
    margin-left: 58.33333333%;
  }

  .col-xsm-offset-13 {
    margin-left: 54.16666667%;
  }

  .col-xsm-offset-12 {
    margin-left: 50%;
  }

  .col-xsm-offset-11 {
    margin-left: 45.83333333%;
  }

  .col-xsm-offset-10 {
    margin-left: 41.66666667%;
  }

  .col-xsm-offset-9 {
    margin-left: 37.5%;
  }

  .col-xsm-offset-8 {
    margin-left: 33.33333333%;
  }

  .col-xsm-offset-7 {
    margin-left: 29.16666667%;
  }

  .col-xsm-offset-6 {
    margin-left: 25%;
  }

  .col-xsm-offset-5 {
    margin-left: 20.83333333%;
  }

  .col-xsm-offset-4 {
    margin-left: 16.66666667%;
  }

  .col-xsm-offset-3 {
    margin-left: 12.5%;
  }

  .col-xsm-offset-2 {
    margin-left: 8.33333333%;
  }

  .col-xsm-offset-1 {
    margin-left: 4.16666667%;
  }

  .col-xsm-offset-0 {
    margin-left: 0%;
  }
}
