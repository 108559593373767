.pl-btn {
  box-shadow: none;

  &.btn {
    font-weight: 600;
  }

  &.btn-lg {
    font-size: 16px;
  }

  &.btn-xlg {
    font-size: 16px;
    padding: 16px 18px;
  }

  &.btn-inline-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &.btn-default {
    background-color: #000000;
    border: 1px solid #000000;
    color: #ffffff;
    cursor: pointer;
    border-radius: 3px;

    &:not(.flat) {
      box-shadow: 2px 2px 5px #cccccc;
    }

    &.inverse {
      background-color: transparent;
      color: #000000;

      &:hover,
      &.active {
        background-color: #000000;
        border-color: #000000;
        color: #ffffff;
      }
    }

    &:hover,
    &.active {
      color: #ffffff;
      background-color: #000000;
      border-color: #000000;
    }

    &:focus,
    &.focus {
      background-color: #000000;
      border-color: #000000;
      color: #ffffff;
    }

    &.disabled,
    &[disabled] {
      background-color: #141414;
      border-color: #141414;
      color: #ffffff;
      pointer-events: none;
      opacity: 1;
    }

    &:hover,
    &.active {
      background-color: transparent;
      color: #000000;
    }

    &:focus,
    &.focus {
      background: #777777;
      border-color: #777777;
      color: #ffffff;
    }

    &.disabled,
    &[disabled] {
      background-color: #848484;
      border-color: #848484;
      color: #ffffff;
    }
  }

  &.btn-success {
    background-color: #88ccab;
    border: 1px solid #88ccab;
    color: #ffffff;
    cursor: pointer;
    border-radius: 3px;

    &:not(.flat) {
      box-shadow: 2px 2px 5px #cccccc;
    }

    &.inverse {
      background-color: transparent;
      color: #88ccab;

      &:hover,
      &.active {
        background-color: #88ccab;
        border-color: #88ccab;
        color: #ffffff;
      }
    }

    &:hover,
    &.active {
      border-color: #88ccab;
      color: #ffffff;
      background-color: #64bd92;
      border-color: #64bd92;
    }

    &:focus,
    &.focus {
      background-color: #52b585;
      border-color: #52b585;
      color: #ffffff;
    }

    &.disabled,
    &[disabled] {
      background-color: #a5d8bf;
      border-color: #a5d8bf;
      color: #ffffff;
      pointer-events: none;
      opacity: 1;
    }
  }

  &.btn-warning {
    background-color: #f0ad4e;
    border: 1px solid #f0ad4e;
    color: #ffffff;
    cursor: pointer;
    border-radius: 3px;

    &:not(.flat) {
      box-shadow: 2px 2px 5px #cccccc;
    }

    &.inverse {
      background-color: transparent;
      color: #f0ad4e;

      &:hover,
      &.active {
        background-color: #f0ad4e;
        border-color: #f0ad4e;
        color: #ffffff;
      }
    }

    &:hover,
    &.active {
      border-color: #f0ad4e;
      color: #ffffff;
      background-color: #ec971f;
      border-color: #ec971f;
    }

    &:focus,
    &.focus {
      background-color: #df8a13;
      border-color: #df8a13;
      color: #ffffff;
    }

    &.disabled,
    &[disabled] {
      background-color: #f3be74;
      border-color: #f3be74;
      color: #ffffff;
      pointer-events: none;
      opacity: 1;
    }
  }

  &.btn-danger {
    background-color: #d30000;
    border: 1px solid #d30000;
    color: #ffffff;
    cursor: pointer;
    border-radius: 3px;

    &:not(.flat) {
      box-shadow: 2px 2px 5px #cccccc;
    }

    &.inverse {
      background-color: transparent;
      color: #d30000;

      &:hover,
      &.active {
        background-color: #d30000;
        border-color: #d30000;
        color: #ffffff;
      }
    }

    &:hover,
    &.active {
      border-color: #d30000;
      color: #ffffff;
      background-color: #a00000;
      border-color: #a00000;
    }

    &:focus,
    &.focus {
      background-color: #860000;
      border-color: #860000;
      color: #ffffff;
    }

    &.disabled,
    &[disabled] {
      background-color: #fc0000;
      border-color: #fc0000;
      color: #ffffff;
      pointer-events: none;
      opacity: 1;
    }
  }

  &.btn-animate {
    transition: all 0.15s ease-in-out;
  }
}
