@import './mixins/utilities.scss';
@import './mixins/form.scss';
@import './mixins/button.scss';
@import './mixins/modal.scss';
@import './mixins/tooltip-popover.scss';
@import './mixins/colomn.scss';

.fade {
  transition: opacity 0.3s ease-in-out;
}
