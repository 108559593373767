.form-control {
  transition: all 0.3s ease-in-out;
  box-shadow: none;
}

.form-horizontal {
  &.pl-form-horizontal {
    .form-group {
      font-family: 'brandon-grotesque', 'Helvetica Neue';

      &:last-child {
        margin-bottom: 0;
      }

      .control-label {
        text-align: left;
        text-transform: uppercase;
      }
    }

    .form-wrapper {
      &.disabled {
        pointer-events: none;

        .dropdown-container .styledSelect:after,
        .icon-selection {
          display: none;
        }
      }

      .value-per-price {
        font-size: 12px;
        text-transform: uppercase;
      }

      select {
        border-radius: 0;
      }
    }

    .grid-style li {
      margin-bottom: 0;
    }

    .grid-item {
      font-size: 0;
      margin: -5px;

      li {
        padding: 5px;
      }
    }

    li:not(.btn-address) {
      > a {
        color: inherit;
        display: block;
      }

      i {
        float: right;
        display: inline-block;
        font-size: 0.8em;
      }
    }

    .box-style {
      position: relative;
      margin: 0 2.5px;
      margin-top: 10px;
      height: 75px;
      width: 75px;
      @media (min-width: 768px) {
        margin-top: 0;
        height: 40px;
        width: 40px;
      }
      html.tablet & {
        margin-top: 15px;
        height: 70px;
        width: 70px;
      }

      .box-label {
        display: none !important;
        font-size: 10px;
        padding: 5px 0;
        font-weight: 700;
        white-space: nowrap;
        @media (min-width: 768px) {
          font-size: 12em;
        }
        html.mobile & {
          display: block !important;
        }
        html.tablet & {
          display: block !important;
          font-size: 12px;
        }
      }

      .item-select {
        position: absolute;
        border: 2px solid #000000;
        top: -3px;
        left: -3px;
        right: -3px;
        bottom: -3px;
        display: none;
      }

      .item-container {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
      }

      .item-box {
        background-size: contain;
        border: 1px solid #cccccc;
        height: 100%;
        width: 100%;
      }

      &.box-layout {
        vertical-align: middle;
        height: 80px;
        width: 80px;

        > a {
          height: 100%;
          width: 100%;
        }

        .item-container {
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          border: 2px solid #ebebeb;
          transition: border-color 0.3s ease-in-out;
        }

        &:hover .item-container,
        &.selected .item-container {
          border: 2px solid #cccccc;
        }
      }

      a,
      svg,
      img {
        display: block;
        position: relative;
        height: 100%;
        width: 100%;
      }

      a {
        cursor: pointer;
      }

      > a {
        height: 75px;
        width: 75px;
        @media (min-width: 768px) {
          height: 40px;
          width: 40px;
        }
        html.tablet & {
          height: 70px;
          width: 70px;
        }
      }

      svg {
        path,
        rect {
          stroke: #bcbec0;
        }

        &:hover {
          path,
          rect,
          line {
            stroke: #000000;
          }
        }
      }

      &.selected > a,
      &.checked > a {
        pointer-events: none;
      }

      &.selected .item-select,
      &.checked .item-select {
        display: block;
      }

      &.selected.border-fade .item-select,
      &.checked.border-fade .item-select {
        display: none;
      }

      &.selected path,
      &.checked path,
      &.selected rect,
      &.checked rect,
      &.selected line,
      &.checked line {
        stroke: #000000;
      }

      &.selected img,
      &.checked img {
        border: 1px solid #000000;
      }

      &.selected .css-border-fade:after,
      &.checked .css-border-fade:after {
        opacity: 1;
        border: 2px solid #000000;
      }

      &.selected .css-border-fade:before,
      &.checked .css-border-fade:before {
        opacity: 1;
      }
    }

    .tooltip {
      text-transform: none;
    }
  }
}

input {
  &[type='text'],
  &[type='password'],
  &[type='email'] {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
}
